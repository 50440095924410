<template>
    <div id="Stages">
        <b-container>
            <b-card no-body>
                <b-tabs pills card vertical>
                    <b-tab v-for="(stages, index) in Stages" :title="stages.stage +'. '+ stages.name ">
                        <b-card-text>
                            <h3>Тариф {{stages.stage}}</h3>
                            <h4>{{stages.name}}</h4>
                            <ol>
                                <li v-for="(feature,index) in stages.list" :key="index">{{feature}}</li>
                            </ol>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Stages",
        data(){
            return{
                Stages:[
                    {
                        name:'Тариф-5',
                        stage:'5',
                        list:[
                            '6-ти метровый кузов',
                            'Грузоподъемность 2.5 - 3 тонны',
                            '25-30 куб.м',
                            '12 евро паллет',
                            'Минимальный заказ 5 часов',
                            '8500/1300/45',
                            '1000₽ последующий час',
                            '30₽/км за МКАД в круг',
                        ]
                    },
                    {
                        name:'Тариф-6',
                        stage:'6',
                        list:[
                            '6 метровый кузов',
                            'До  5 тонн',
                            '36 м.куб.',
                            'До 15 евро паллет',
                            '11200/1400/55',
                            'Минимальный заказ 7 часа',
                        ]
                    },
                    {
                        name:'Тариф-7',
                        stage:'7',
                        list:[
                            '7.2 метровый кузов',
                            '7 тонн',
                            '42 м.куб.',
                            '18 евро паллет',
                            '12800/1600/60',
                            'Минимальный заказ 7 часов',
                        ]
                    },
                    {
                        name:'Добавить дополнительные услуги',
                        stage:'8',
                        list:[
                            'Гидроборт   +1 час',
                            'Рокла   +1 час',
                            'Растенковка бока/верх   +1 час/каждая',
                            'Грузчики по 450₽/час',
                            'Минимальный заказ 4 часа сборка/раззборка',
                        ]
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>